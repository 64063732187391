import { useStore } from 'effector-react'

import { Controller, Path } from 'react-hook-form'

import { useMemo } from 'react'

import { FieldLabelCapitalized } from '../FieldLabel'
import { MultipleSelectAutocomplete } from '../MultipleSelectAutocomplete'

import {
  FormAttribute,
  FormAttributeValue,
  AttributeFieldItemProps,
} from './AttributeFieldItem.types'

export const AttributeFieldItem = <
  FormValue extends {
    attributes: FormAttribute[]
  },
>({
  attributeId,
  deleted,
  attributeName,
  control,
  loading,
  optionsLoading,
  disabled,
  attributesService,
  index,
  attributesFormService,
  dataTestIdPrefix,
}: AttributeFieldItemProps<FormValue>) => {
  const optionsByAttributeId = useStore(
    attributesFormService.optionsByAttributeId$,
  )
  const options = useMemo(
    () => (deleted ? [] : optionsByAttributeId[attributeId] || []),
    [optionsByAttributeId, deleted, attributeId],
  )
  return (
    <div key={attributeName}>
      <FieldLabelCapitalized>{attributeName}</FieldLabelCapitalized>
      <Controller
        render={({ field }) => (
          <MultipleSelectAutocomplete
            onChange={(_event, value) => {
              field.onChange(value)
            }}
            value={field.value as FormAttributeValue[]}
            noOptionsText={loading ? 'Идёт загрузка' : 'Нет доступных свойств'}
            options={options}
            loading={optionsLoading}
            disabled={disabled}
            getOptionLabel={option => (option as FormAttributeValue).name || ''}
            filterOptions={(options, searchValue: string) =>
              options.filter(
                option =>
                  !(option as FormAttributeValue).hidden &&
                  (option as FormAttributeValue).name
                    .toLocaleLowerCase()
                    .includes(searchValue.toLocaleLowerCase()),
              )
            }
            groupBy={option => (option as FormAttributeValue).groupLabel}
            onOpen={() => {
              if (optionsByAttributeId[attributeId]?.length > 0) {
                return
              }

              attributesService.fetchAttributePopulatedWithArchived({
                attrId: attributeId,
              })
            }}
            isOptionEqualToValue={(option, value) =>
              (option as FormAttributeValue).id ===
              (value as FormAttributeValue).id
            }
            dataTestIdPrefix={dataTestIdPrefix}
          />
        )}
        name={`attributes.${index}.values` as Path<FormValue>}
        control={control}
      />
    </div>
  )
}
