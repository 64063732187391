import { userInfo$ } from '@gmini/common'
import { useStore } from 'effector-react'

import { useEffect } from 'react'

import { fetchCompanyList } from '../../company.store'

import { attributesService } from '../../attribute.store'
import { fetchStatusList } from '../../issueStatus.store'
import { fetchRoleList } from '../../role.store'
import { fetchAllUserList, fetchProjectUserList } from '../../user.store'

import { CreateIssue } from './CreateIssue'
import { fetchProjectList, projectList$ } from './model'

export const CreateIssuePage = () => {
  const { projectUrn } = window.xprops

  const userInfo = useStore(userInfo$)
  const projectList = useStore(projectList$)

  useEffect(() => {
    fetchProjectList.submit()
    fetchRoleList.submit()
    fetchCompanyList.submit()
    fetchStatusList.submit()
    fetchAllUserList.submit({})
    attributesService.fetchAttributeList({})
    if (projectUrn) {
      fetchProjectUserList.submit({ projectUrn })
    }
  }, [projectUrn])

  if (!userInfo || !projectUrn) {
    return null
  }
  return (
    <CreateIssue
      projectList={projectList}
      projectUrn={projectUrn}
      onClose={window.xprops?.onCloseIFrame}
      onCreateIssue={window.xprops?.onCreateIssue}
      renderFileValidationErrorsPopup={
        window.xprops?.renderFileValidationErrorsPopup
      }
      lockProjectSelect
    />
  )
}
