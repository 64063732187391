import { SeoEventManager } from '@gmini/common'
import Keycloak from 'keycloak-js'

export const wsUrl = getEnv('SPA_WS_URL')
export const wsUrlV2 = getEnv('SPA_WS_URL_V2')
export const sentryUrl = getEnv('SPA_FIELD_INSPECTION_SENTRY_DSN')
export const envType = getEnv('SPA_ENV_TYPE')
export const keycloakUrl = getEnv('SPA_KEYCLOAK_SERVER_URL')
export const clientId = getEnv('SPA_KEYCLOAK_CLIENT_ID')
export const realm = getEnv('SPA_KEYCLOAK_REALM')
export const seoEnabled = getEnv('SPA_SEO') === 'true'
export const envName = getEnv('SPA_AUTH_RESOURCE_ENV')
export const includeDevFeatures = getEnv('SPA_INCLUDE_DEV_FEATURES') === 'true'
export const envLinks = getEnv('SPA_LINKS_CONFIG')
export const gStationDocumentManagementUrl = getEnv(
  'SPA_G_STATION_DOCUMENT_MANAGEMENT_SERVER_URL',
)
export const omniManagementUrl = getEnv('SPA_OMNI_MANAGEMENT_SERVER_URL')
export const localBackUrl = process.env.LOCAL_BACK_URL
export const localDevUrl = 'http://127.0.0.1:3001/'

export const seoEventManager = new SeoEventManager({ envName, seoEnabled })

export const MAX_NESTING_LEVEL = 6

export const DEFAULT_FETCH_LIMIT = 40

export const omniNotificationsAuthenticateTimeout = 5000

const {
  SPA_LOG_EFFECTOR,
}: {
  readonly SPA_LOG_EFFECTOR: undefined | string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} = globalThis as any

export const logEffector = !!SPA_LOG_EFFECTOR && SPA_LOG_EFFECTOR !== 'false'

function getEnv(name: string): string {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const value: null | string = (globalThis as any)[name] || null

  if (value == null) {
    return ''
  }

  return value
}

export const keycloakClient = Keycloak({
  clientId,
  realm,
  url: keycloakUrl,
})
