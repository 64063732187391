import { CrossedChain, DatePicker, LongText, Tag, Tooltip } from '@gmini/ui-kit'
import { useStore } from 'effector-react'
import { useCallback, useEffect, useState } from 'react'

import { AssigneeListItem, useAssignees } from '@gmini/helpers'

import {
  FormAttributeValue,
  RenderAssignees,
  SimpleBubble,
} from '@gmini/components'

import * as smApi from '@gmini/sm-api-sdk'

import {
  getDaysNumberBeforeOrAfterDeadline,
  getDeadlineNameDay,
  getDeadlineStatus,
  getDeadlineStyle,
} from '@gmini/common'

import * as api from '@gmini/ism-api-sdk'

import moment from 'moment'

import dayjs, { Dayjs } from 'dayjs'

import { ClickAwayListener } from '@material-ui/core'

import { useSnackbar } from 'notistack'

import {
  SelectByGroupsMultipleWithPreview,
  SelectWithPreview,
} from '../../molecules'

import { updateGTechIssue } from '../../issue.store'

import { SelectPreview } from '../../atoms'

import { DEFAULT_DISPLAY_DATE_FORMAT } from '../../../constants'

import {
  assigneeAllUserList$,
  assigneeCompanyList$,
  assigneeGroupList$,
  assigneeRoleList$,
} from '../../assigneeGroupList'

import { Mode } from '../../atoms/SelectPreview/SelectPreview.types'

import { editIssuePending$ } from '../../organisms/EditIssuePopup/editIssuePending'

import { attributesFormService, attributesService } from '../../attribute.store'

import { MultipleSelectAutocompleteWithPreview } from '../../molecules/MultipleSelectAutocompleteWithPreview'

import { allUserList$, projectUserList$ } from '../../user.store'

import { seoEventManager } from '../../../config'

import {
  AdditionalFieldName,
  AdditionalFieldNameCapitalized,
  DeadlineEditMode,
  DeadlineWrapper,
  EndAdornmentWrapper,
  FieldName,
  GTechSimpleLogo,
  ItemAttributeValueWrapper,
  ItemPlaceholder,
  LinkedEntityValue,
  LinkedEntityValueText,
  OpenInBrowserButton,
} from './EditIssue.styled'
import { AdditionalContentProps } from './types'

const copiedDelay = 5000

export const AdditionalContent = ({
  issue,
  selectedProject,
  onUpdateIssueHandler,
}: AdditionalContentProps) => {
  const issuePending = useStore(editIssuePending$)
  const allUserList = useStore(allUserList$)

  const assigneeGroupList = useStore(assigneeGroupList$)
  const assigneeUserList = useStore(projectUserList$)

  const [copied, setCopied] = useState(false)

  const [deadlineState, setDeadlineState] = useState<Dayjs | null>(
    dayjs(issue.deadline),
  )
  const [deadlineError, setDeadlineError] = useState(false)

  const [modeDeadline, setModeDeadline] = useState(Mode.preview)
  const optionsByAttributeId = useStore(
    attributesFormService.optionsByAttributeId$,
  )

  const fetchAttributeValuesPending = useStore(
    attributesService.fetchAttributeValuesPending$,
  )

  const getAssignees = useAssignees({
    assigneeRoleList$,
    assigneeUserList$: assigneeAllUserList$,
    assigneeCompanyList$,
  })

  const assignees = getAssignees(issue.assignees)

  useEffect(() => {
    setDeadlineState(dayjs(issue.deadline))
  }, [issue.deadline])

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), copiedDelay)

      return () => clearTimeout(timer)
    }
  }, [copied])

  const { enqueueSnackbar } = useSnackbar()

  const onUpdateIssue = useCallback(
    async (updateParams: Partial<api.GTechIssue.UpdateParams>) => {
      const nextIssue = await updateGTechIssue({
        id: issue.id,
        version: issue.version,
        ...updateParams,
      })

      onUpdateIssueHandler?.(issue, nextIssue)
      return issue
    },
    [issue, onUpdateIssueHandler],
  )

  const onChangeDeadline = useCallback(
    (date: Dayjs | null) => {
      setModeDeadline(Mode.preview)

      if (
        date &&
        (!date.isValid() || date.isBefore(new Date().toDateString()))
      ) {
        enqueueSnackbar('Указана некорректная дата в поле "Срок"', {
          variant: 'error',
        })
        return
      }

      const newDateISOString = date ? date.toISOString() : null

      if (newDateISOString !== (issue.deadline || null)) {
        onUpdateIssue({
          deadline: newDateISOString,
        })
      }
    },
    [issue.deadline, enqueueSnackbar, onUpdateIssue],
  )

  const renderPreviewValueAssignee = useCallback(
    (value: AssigneeListItem[]) =>
      value.length ? (
        <RenderAssignees assignees={value} maxWidth='220px' />
      ) : null,
    [],
  )

  const isIssueClosed = issue.status === api.IssueStatus.Enum.CLOSED

  let linkedEntityValue = null

  if (issue.linkedEntity) {
    linkedEntityValue = (
      <>
        <GTechSimpleLogo internalColor='#00003D' outerColor='#353B60' />
        <LinkedEntityValueText>
          <LongText
            partSize={10}
            withoutRightText
          >{`${issue.linkedEntity.moduleName} / ${issue.linkedEntity.entityName}`}</LongText>
        </LinkedEntityValueText>

        <Tooltip
          title={`Открыть в новом окне ${issue.linkedEntity.moduleName}/${issue.linkedEntity.entityName}`}
        >
          <OpenInBrowserButton
            onClick={() => {
              window.open(issue.linkedEntity?.link, '_blank')

              seoEventManager.push({
                event: 'Gstation_Issues_Issue_ChainFileOpen',
                payload: {},
              })
            }}
          />
        </Tooltip>
      </>
    )
  }

  if (issue.linkedEntity === null) {
    linkedEntityValue = (
      <>
        <CrossedChain />
        Не привязано
      </>
    )
  }

  const isIssueClosedInTime =
    isIssueClosed &&
    moment(issue.deadline, moment.ISO_8601)
      .startOf('d')
      .add(1, 'd')
      .diff(moment(issue.updatedAt, moment.ISO_8601), 'day', true) > 0

  const daysNumberBeforeOrAfterDeadline = getDaysNumberBeforeOrAfterDeadline(
    issue.deadline,
  )

  const deadlineStatus = getDeadlineStatus(daysNumberBeforeOrAfterDeadline)

  const deadlineStyle = getDeadlineStyle(deadlineStatus)

  const deadlineBadgeText = getDeadlineNameDay(daysNumberBeforeOrAfterDeadline)

  const attributeValues = useStore(attributesFormService.attributeValues$)

  const attributesForEditing = attributesFormService.useAttributesForEditing(
    issue.attributes,
  )

  const attributeValuesById = useStore(attributesService.attributeValueById$)

  const fetchAttributePendingMap = useStore(
    attributesService.fetchAttributePendingMap$,
  )

  const renderPreviewAttributeValue = useCallback(
    (value: FormAttributeValue[]) => (
      <ItemAttributeValueWrapper>
        {value.length ? (
          value.map(item => <SimpleBubble key={item?.id} title={item?.name} />)
        ) : (
          <ItemPlaceholder>Не задан</ItemPlaceholder>
        )}
      </ItemAttributeValueWrapper>
    ),
    [],
  )

  const onUpdateAttribute = useCallback(
    (attributeId: number, value: FormAttributeValue[]) => {
      onUpdateIssue({
        attributes: [
          ...(issue.attributes?.filter(attr => attr.id !== attributeId) || []),
          {
            id: attributeId,
            valueIds: value.map(({ id }) => id),
          },
        ],
      })
    },
    [issue.attributes, onUpdateIssue],
  )

  const [currentAuthor] = allUserList.filter(user => user.id === issue.author)

  const onChangeAuthor = useCallback(
    async (value: smApi.Auth.UserData | null) => {
      if (value) {
        await onUpdateIssue({
          author: value.id,
        })
      }
    },
    [onUpdateIssue],
  )

  return (
    <>
      <div>
        <AdditionalFieldName>Назначено на</AdditionalFieldName>
        <SelectByGroupsMultipleWithPreview
          onSubmit={assignees =>
            onUpdateIssue({
              assignees,
            })
          }
          initValue={assignees}
          placeholder='Не задан'
          groups={assigneeGroupList}
          getOptionLabel={(option: AssigneeListItem) => option.label}
          disabled={issuePending || isIssueClosed}
          editable={!!issue.allowedActions?.changeableFields?.assignees}
          renderPreviewValue={renderPreviewValueAssignee}
          dataTestIdPrefix='editIssueAdditionalContentSelectAssignee'
        />
      </div>
      <div>
        <AdditionalFieldName>Автор</AdditionalFieldName>
        <SelectWithPreview
          onChange={onChangeAuthor}
          placeholder='Не задан'
          options={assigneeUserList}
          value={currentAuthor}
          getOptionLabel={value => value.name}
          disabled={issuePending || isIssueClosed}
          editable={!!issue.allowedActions?.changeableFields?.author}
          renderValue={value => value.name}
        />
      </div>
      <div>
        <AdditionalFieldName>Срок исполнения</AdditionalFieldName>
        <ClickAwayListener
          onClickAway={() => {
            if (modeDeadline === Mode.edit) {
              onChangeDeadline(deadlineState)
            }
          }}
        >
          <DeadlineWrapper>
            <SelectPreview
              renderValue={issue => (
                <>
                  {issue?.deadline ? (
                    moment(issue.deadline).format(DEFAULT_DISPLAY_DATE_FORMAT)
                  ) : (
                    <ItemPlaceholder>Не задан</ItemPlaceholder>
                  )}
                  {!isIssueClosedInTime &&
                    deadlineStatus &&
                    deadlineStatus !== 'ok' && (
                      <EndAdornmentWrapper>
                        <Tag
                          text={deadlineBadgeText}
                          color={deadlineStyle?.color}
                        />
                      </EndAdornmentWrapper>
                    )}
                </>
              )}
              value={issue}
              mode={modeDeadline}
              onChangeMode={setModeDeadline}
              disabled={issuePending || isIssueClosed}
              editable={!!issue.allowedActions?.changeableFields?.deadline}
              renderEditMode={() => (
                <DeadlineEditMode>
                  <DatePicker
                    label='Укажите срок'
                    value={deadlineState}
                    onAccept={onChangeDeadline}
                    onChange={v => {
                      if (v?.isBefore(new Date().toDateString())) {
                        setDeadlineError(true)
                      } else {
                        setDeadlineError(false)
                      }
                      setDeadlineState(v)
                    }}
                    disabled={issuePending || isIssueClosed}
                    onReset={() => onChangeDeadline(null)}
                    background='#f4f4f8'
                    slotProps={{
                      textField: {
                        error: false,
                        onKeyDown: event => {
                          switch (event.key) {
                            case 'Tab':
                            case 'Enter': {
                              onChangeDeadline(deadlineState)
                            }
                          }
                        },
                      },
                    }}
                    error={deadlineError}
                    format={DEFAULT_DISPLAY_DATE_FORMAT}
                    autoFocus
                    disablePast
                  />
                </DeadlineEditMode>
              )}
              previewStyle={!isIssueClosedInTime ? { ...deadlineStyle } : {}}
            />
          </DeadlineWrapper>
        </ClickAwayListener>
      </div>

      <>
        {attributesForEditing.map(attr => {
          const preparedIssueAttributes =
            attributesFormService.getFormAttributesValues(
              issue?.attributes || [],
              attr.id,
              attributeValues,
            )

          const options = attr.deleted
            ? []
            : optionsByAttributeId[attr.id] || []

          return (
            <div key={attr.id}>
              <AdditionalFieldNameCapitalized>
                {attr.name}
              </AdditionalFieldNameCapitalized>
              <MultipleSelectAutocompleteWithPreview
                initValue={preparedIssueAttributes}
                options={options}
                getOptionLabel={option =>
                  attributeValuesById[option.id].name || ''
                }
                loading={!!fetchAttributePendingMap[attr.id]}
                optionsLoading={fetchAttributeValuesPending}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                groupBy={option => option.groupLabel}
                filterOptions={options =>
                  options.filter(option => !option.hidden)
                }
                onOpen={() => {
                  if (optionsByAttributeId[attr.id]?.length > 0) {
                    return
                  }

                  attributesService.fetchAttributePopulatedWithArchived({
                    attrId: attr.id,
                  })
                }}
                disabled={
                  issuePending || isIssueClosed || fetchAttributeValuesPending
                }
                editable={
                  !!issue.allowedActions?.changeableFields?.attributes &&
                  !issuePending &&
                  !fetchAttributeValuesPending
                }
                renderPreviewValue={renderPreviewAttributeValue}
                onSubmit={value => onUpdateAttribute(attr.id, value)}
                placeholder='Не задано'
                dataTestIdPrefix='editIssueAdditionalContentSelectAttribute'
              />
            </div>
          )
        })}
      </>

      {selectedProject.sourceType !== 'Bim360' && (
        <div>
          <FieldName>Привязано к</FieldName>

          <LinkedEntityValue disabled={!issue.linkedEntity}>
            {linkedEntityValue}
          </LinkedEntityValue>
        </div>
      )}
    </>
  )
}
