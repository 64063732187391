import { userInfo$ } from '@gmini/common'
import {
  FilterAssigneeMultiple,
  FilterAttribute,
  FilterDate,
  FilterPanel,
  FilterUser,
} from '@gmini/components'
import { useStore } from 'effector-react'
import { useEffect } from 'react'

import {
  ASSIGNEES_TL,
  ATTRIBUTES_TL,
  CREATED_DATE_CODE_TL,
  CREATED_DATE_RANGE_TL,
  AUTHOR_IDS_TL,
  IssueTemplateListFiltersType,
  IssueTemplateQueryType,
  UPDATED_DATE_CODE_TL,
  UPDATED_DATE_RANGE_TL,
  filtersTitle,
} from '@gmini/helpers'

import { PROJECT_IS_NOT_SELECTED_ERROR } from '../../../constants'
import { assigneeGroupList$ } from '../../assigneeGroupList'
import { fetchCompanyListPending$ } from '../../company.store'
import { fetchRoleListPending$ } from '../../role.store'
import {
  fetchProjectUserListPending$,
  useProjectUserList,
} from '../../user.store'

import { filterService } from '../../issueTemplateFilter.store'

import { attributesService } from '../../attribute.store'

import {
  allowedFilters$,
  fetchAllowedFilters,
  fetchAllowedFiltersPending$,
} from './model'

type IssueTemplateListFilterPanelProps = {
  onChange: (filters: IssueTemplateListFiltersType) => void
  projectUrn?: string | null
}

export const IssueTemplateListFilterPanel = ({
  onChange,
  projectUrn,
}: IssueTemplateListFilterPanelProps) => {
  const allowedFilters = useStore(allowedFilters$)
  const userList = useProjectUserList()
  const fetchProjectUserListPending = useStore(fetchProjectUserListPending$)
  const fetchCompanyListPending = useStore(fetchCompanyListPending$)
  const fetchRoleListPending = useStore(fetchRoleListPending$)
  const fetchAllowFiltersPending = useStore(fetchAllowedFiltersPending$)
  const userInfo = useStore(userInfo$)
  const assigneeGroupList = useStore(assigneeGroupList$)
  const {
    appliedFilters: { appliedFilters$, useUpdateFilter },
  } = filterService

  const appliedFilters = useStore(appliedFilters$)
  const { update } = useUpdateFilter()
  const attributes = attributesService.useAttributeList({ archived: true })

  useEffect(() => {
    if (!projectUrn) {
      return
    }

    fetchAllowedFilters({
      projectUrn,
    })
  }, [projectUrn])

  const onChangeHandler = (filter: IssueTemplateQueryType) => {
    update(filter)
    const filters = appliedFilters$.getState()
    onChange(filters)

    if (!projectUrn) {
      throw new Error(PROJECT_IS_NOT_SELECTED_ERROR)
    }

    fetchAllowedFilters({
      projectUrn,
    }) // Чтобы хэндлить изменения замечаний другими пользователями.
  }

  const fetchAttributePendingMap = useStore(
    attributesService.fetchAttributePendingMap$,
  )
  const attributeValueById = useStore(attributesService.attributeValueById$)
  const attributeItems = attributes
    .filter(
      attr =>
        !attr.deleted &&
        allowedFilters?.attributeValueIds.some(id => {
          const item = attributeValueById[id]
          return item?.attribute?.id === attr.id
        }),
    )
    .map(({ id, name }) => ({
      title: name,
      component: (
        <FilterAttribute
          dataTestIdFor={{ filterItem: { name: 'AttibuteValueId' } }}
          allowedItems={allowedFilters?.attributeValueIds || []}
          urlKey={ATTRIBUTES_TL}
          attributeValueById$={attributesService.attributeValueById$}
          attributeId={id}
          loading={false}
          disabled={!allowedFilters && fetchAttributePendingMap[id]}
          countSkeletonItems={6}
          onChange={onChangeHandler}
          value={appliedFilters.attributeValueIds || []}
          hideDeleted
        />
      ),
    }))

  return (
    <FilterPanel
      items={[
        {
          title: filtersTitle.updatedAt,
          component: (
            <FilterDate
              dataTestIdFor={{ filterItem: { name: 'ChangingDate' } }}
              allowedRange={allowedFilters?.updatedDateRange || null}
              urlKeyCode={UPDATED_DATE_CODE_TL}
              urlKeyValue={UPDATED_DATE_RANGE_TL}
              disabled={!allowedFilters && fetchAllowFiltersPending}
              onChange={onChangeHandler}
              value={appliedFilters?.updatedDateCode || null}
            />
          ),
        },
        {
          title: filtersTitle.createAt,
          component: (
            <FilterDate
              dataTestIdFor={{ filterItem: { name: 'CreationDate' } }}
              allowedRange={allowedFilters?.createdDateRange || null}
              urlKeyCode={CREATED_DATE_CODE_TL}
              urlKeyValue={CREATED_DATE_RANGE_TL}
              onChange={onChangeHandler}
              value={appliedFilters.createdDateCode || null}
              disabled={!allowedFilters && fetchAllowFiltersPending}
            />
          ),
        },
        {
          title: filtersTitle.author,
          component: (
            <FilterUser
              userList={userList}
              urlKey={AUTHOR_IDS_TL}
              allowedItems={allowedFilters?.authorIds || []}
              placeholder='Выбрать автора'
              disabled={
                fetchProjectUserListPending ||
                (!allowedFilters && fetchAllowFiltersPending)
              }
              userInfo={userInfo}
              labelFirstEl='Я автор'
              onChange={onChangeHandler}
              value={appliedFilters.authorIds || []}
            />
          ),
        },
        {
          title: filtersTitle.assignee,
          component: (
            <FilterAssigneeMultiple
              assigneeGroupList={assigneeGroupList}
              urlKey={ASSIGNEES_TL}
              allowedItems={allowedFilters?.assignees || []}
              placeholder='Выбрать на кого на...'
              disabled={
                fetchProjectUserListPending ||
                fetchRoleListPending ||
                fetchCompanyListPending ||
                (!allowedFilters && fetchAllowFiltersPending)
              }
              userInfo={userInfo}
              labelFirstEl='Назначено на меня'
              onChange={onChangeHandler}
              value={appliedFilters.assignees || []}
            />
          ),
        },
        ...attributeItems,
      ]}
    />
  )
}
